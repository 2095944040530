/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { wait } from '@/helper';
import { createAppAsyncThunk } from '@/helper/redux';
import api from '@/services/api';
import type { IBannerTop, IProductRecommended } from '@/services/types.home';
import { Catalog, Refinements } from '@/types/Catalog';

export const fetchHome = createAppAsyncThunk(
  'fetchHome',
  async (_, { getState, dispatch }) => {
    const configs = getState().config.configs;
    if (!configs?.urls?.length) {
      await wait(1000);
      dispatch(fetchHome());
      return Promise.reject(new Error('urls not found'));
    }
    const banners = configs.urls?.find(url => url.name === 'banners')?.value;

    const endpointsBanner = [
      `${banners}/st-banner-config-web.json`,
      `${banners}/st-vitrine.json`,
      `${banners}/st-vitrine-web.json`
    ];

    const reqBanner = await Promise.allSettled(
      endpointsBanner.map(endpoint => axios.get(endpoint))
    );

    const responseBanners = reqBanner.reduce((acc, current, index) => {
      switch (index) {
        case 0:
          return {
            ...acc,
            bannerTop:
              current.status === 'fulfilled'
                ? current.value.data.slides
                : initialState.bannerTop
          };
        case 1:
          return {
            ...acc,
            productsRecommended:
              current.status === 'fulfilled'
                ? current.value.data
                : initialState.productsRecommended
          };
        case 2:
          return {
            ...acc,
            productsCheckOut:
              current.status === 'fulfilled'
                ? current.value.data
                : initialState.productsCheckOut
          };
        default:
          return acc;
      }
    }, {} as any);

    return {
      responseBanners
    };
  }
);

export const fetchPriceRange = createAppAsyncThunk(
  'fetchPriceRange',
  async () => {
    const reqCatalog = await api.get<Catalog>('/stix/search');
    const priceRange = reqCatalog.data?.Navigations.find(
      item => item.dimensionName === 'stix.pointsPriceRange'
    )?.refinements as Refinements[];

    return {
      priceRange
    };
  }
);

const initialState = {
  bannerTop: [] as IBannerTop[],
  productsCheckOut: {
    configuration: {
      displayCollectionName: ''
    },
    products: []
  } as IProductRecommended,
  productsRecommended: {
    configuration: {
      displayCollectionName: ''
    },
    products: []
  } as IProductRecommended,
  priceRange: [] as Refinements[]
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchHome.fulfilled, (state, action) => {
      state.bannerTop = action.payload.responseBanners?.bannerTop;
      state.productsRecommended =
        action.payload.responseBanners?.productsRecommended;
      state.productsCheckOut = action.payload.responseBanners?.productsCheckOut;
    });
    builder.addCase(fetchHome.rejected, state => {
      state.bannerTop = [];
      state.productsRecommended = {
        configuration: {
          displayCollectionName: ''
        },
        products: []
      };
      state.productsCheckOut = {
        configuration: {
          displayCollectionName: ''
        },
        products: []
      };
    });
    builder.addCase(fetchPriceRange.fulfilled, (state, action) => {
      state.priceRange = action.payload.priceRange;
    });
    builder.addCase(fetchPriceRange.rejected, state => {
      state.priceRange = [];
    });
  },
  reducers: {
    revokeHome(state) {
      state.bannerTop = initialState.bannerTop;
      state.priceRange = initialState.priceRange;
      state.productsCheckOut = initialState.productsCheckOut;
      state.productsRecommended = initialState.productsRecommended;
    }
  }
});

export const { revokeHome } = homeSlice.actions;

export default homeSlice.reducer;
